import { initializeApollo, addApolloState } from 'lib/withApollo'
import { Redirect, GetServerSidePropsResult, GetStaticPropsResult } from 'next'
import { gql } from '@apollo/client'
import { DEFAULT_LOCATIONS } from 'lib/config'
import { locationEntries as locationEntriesQuery } from 'gql/locationEntries.gql'
import { Location_Location_Entry, HomeDesignsSegment_HomeDesignsSegment_Entry } from 'lib/generated/graphql-types'

export type GetPageInitContext = {
  entryQuery: any
  urlPath: string
  props?: any
  revalidate?: number,
}

export const getPageEntry = async ({
  entryQuery,
  urlPath = '',
  props = {},
  revalidate, // default undefined (can only use this if passing from getStaticProps, not getServerSideProps)
}: GetPageInitContext): Promise<GetServerSidePropsResult<any> | GetStaticPropsResult<any>> => {
  // console.log('>>> GET PAGE ENTRY', { urlPath },  { revalidate }, { entryQuery })
  const apolloClient = initializeApollo()
  const entryData = await apolloClient.query(entryQuery)
  // console.log('>>> PAGE ENTRY DATA', entryData?.data?.entry)
  if (entryData?.data?.entry) {
    // Add result to state and return props
    const apolloState = addApolloState(apolloClient, {
      props: {
        ...props,
        entry: entryData?.data?.entry,
        urlPath: urlPath
      },
    })
    // return revalidate value with next.js props
    return revalidate ? { props: apolloState.props, revalidate } : { props: apolloState.props }
  }

  // Look for a redirect or fallback to 404
  const uri = urlPath.replace(/^\//, '') // trim leading forward slash
  // console.log('>>> LOOK FOR ENTRY BY URI', uri)
  const region = uri.split('/')[0] // get first url segment
  if (region) {
    const redirect = await getEntryByUri(region, uri)
    if (redirect) return { redirect }
  }

  // Look for a redirect or fallback to 404
  const redirect = await getRedirect(urlPath)
  if (redirect) return { redirect }

  // 404
  return { notFound: true }
}

export const getLocationParams = async ({ includeNational }: GetLocationParamsContext): Promise<LocationParams[]> => {
  // console.log('>>> GET LOCATIONS')
  const apolloClient = initializeApollo()
  const locationData = await apolloClient.query({ query: locationEntriesQuery })
  const locations = locationData?.data?.entries?.reduce((result: LocationParams[], r: Location_Location_Entry) => {
    r?.children.forEach((l: Location_Location_Entry) => {
      result.push({
        region: r.siteHandle,
        location: l.slug,
      })
    })
    return result
  }, [])
  if (includeNational) {
    // add national region placeholder
    locations.unshift({ region: 'national', location: 'national' })
  }
  return locations
}

export const getSegmentPageParams = async (section: string, type: string): Promise<SegmentPageParams[]> => {
  const apolloClient = initializeApollo()
  const entriesData = await apolloClient.query({
    query: gql`
      query getEntriesParams($siteHandle: [String] = ["national", "vic", "sa", "qld"], $section: [String], $type: [String]) {
        entries(section: $section, type: $type, site: $siteHandle) {
          title
          siteId
          siteHandle
          slug
          ... on homeDesignsSegment_homeDesignsSegment_Entry {
            customPath
          }
        }
      }
    `,
    variables: { section, type },
  })

  return entriesData?.data?.entries?.map((entry: HomeDesignsSegment_HomeDesignsSegment_Entry) => ({
    region: entry.siteHandle,
    slug: entry.slug,
    customPath: entry.customPath
  })) || [];

}

export const getPageSkeleton = async (region = 'vic'): Promise<any> => {
  if (region === 'national') region = 'vic' // default to vic if national (no national nav in Craft)

  const apolloClient = initializeApollo()

  const [headerData, footerData, bottomFooterData] = await Promise.all([
    apolloClient.query({
      query: QUERY_HEADER,
      variables: {
        siteHandle: region,
        section: 'header'
      },
    }),
    apolloClient.query({
      query: QUERY_FOOTER,
      variables: {
        siteHandle: region,
        section: 'footer'
      },
    }),
    apolloClient.query({
      query: QUERY_BOTTOM_FOOTER,
      variables: {
        siteHandle: region,
      },
    })
  ])

  // console.log('>>> HEADER DATA', headerData?.data?.entries)

  return {
    headerData: headerData?.data?.entries,
    footerData: footerData?.data?.entries,
    bottomFooterData: bottomFooterData?.data?.globalSet,
  }
}

export const getGlobalSeoData = async (): Promise<any> => {
  const apolloClient = initializeApollo()
  const seoData = await apolloClient.query({
    query: gql`
      query getGlobalSeoData {
        meta: globalSet(handle: "meta") {
          ... on meta_GlobalSet {
            global__metaTitleSuffix
            global__metaImage {
              title
              url
              width
              height
            }
          }
        }
      }
    `,
  })
  // console.log('>>> RAW SEO DATA', seoData);
  // console.log('>>> GLOBAL SEO DATA', seoData?.data?.meta)
  return seoData?.data?.meta
}

export const verifyLocation = async (region: string, location: string): Promise<boolean> => {
  // console.log('>>> VERIFY LOCATION', region, location)
  const apolloClient = initializeApollo()
  const locationData = await apolloClient.query({ query: locationEntriesQuery })
  const allLocations: LocationParams[] = []
  locationData?.data?.entries?.forEach((region: Location_Location_Entry) => {
    region?.children.forEach((location: Location_Location_Entry) => {
      allLocations.push({
        region: region?.siteHandle,
        location: location.slug,
      })
    })
  })
  allLocations.push({
    region: 'national',
    location: 'national',
  })

  return !!allLocations.find((l) => l.region === region && l.location === location)
}

export const getEntryByUri = async (region: string, uri: string): Promise<Redirect> => {
  const regex = new RegExp(`^\/?${region}\/`) // trim region and leading forward slash
  const formattedUri = uri.replace(regex, '')
  // console.log('>>> LOOK FOR ENTRY BY URI', region, uri, `(formatted: ${formattedUri})`)
  const apolloClient = initializeApollo()
  const entryQuery = gql`
    query findByUri($site: [String], $uri: [String]) {
      entry(site: $site, uri: $uri) {
        title
        url
      }
    }
  `
  const entryResult = await apolloClient.query({
    query: entryQuery,
    variables: { site: region, uri: formattedUri },
  })

  // console.log('>>> ENTRY RESULT', entryResult?.data?.entry);

  if (entryResult?.data?.entry) {
    const url = new URL(entryResult?.data?.entry?.url)
    const defaultLocations = Object.create(DEFAULT_LOCATIONS)
    const defaultLocation = defaultLocations[region]?.location || '[location]'
    const destination = url.pathname.replace(`${region}/`, `${region}/${defaultLocation}/`)
    // console.log('>>> REDIRECT TO', entryResult?.data?.entry?.title, destination)
    return { destination, permanent: true }
  }
  return null
}

type RedirectPath = {
  path: string
  statusCode: number
}

const STATIC_REDIRECTS: { [key: string]: RedirectPath } = {
  '/test-all': { path: '/vic/melbourne/all-components', statusCode: 302 },
  '/vic/test-all': { path: '/vic/melbourne/all-components', statusCode: 302 },
}

const getStaticRedirect = (url: string): RedirectPath => {
  const testUrl = new URL(url, 'https://base-url')
  return STATIC_REDIRECTS[testUrl.pathname]
}

export const getRedirect = async (url: string): Promise<Redirect> => {
  // console.log('>>> LOOK FOR REDIRECTS', url)
  const redirect = getStaticRedirect(url)
  // const redirect = false // await apolloClient.query(redirectQuery)
  if (redirect) {
    console.log('>>> REDIRECT TO', redirect.path)
    return {
      destination: redirect.path,
      permanent: redirect.statusCode === 301 || redirect.statusCode === 308, // OR statusCode: 301|302|303|307|308
    }
  }
  return null
}

const QUERY_HEADER = gql`
  query getHeaderOne($siteHandle: [String]){
    entries(site: $siteHandle, section: "header"){
      ...on header_phone_Entry {
        phone
      }
      ...on header_item_Entry{
        id
        parent{
          id
        }
        title
        slug
        externalLink
        internalLink {
          id
          uri
        }
      }
    }
  }
`

const QUERY_FOOTER = gql`
  query getFooterData($siteHandle: [String]){
    entries(site: $siteHandle, section: "footer"){
      ...on footer_headerUpItems_Entry{
        id
        typeHandle
        children{
          title
          ...on footer_upItem_Entry{
            title
            text
            buttonText
            buttonLink{
              url
            }
          }
        }
      }
      ...on footer_headerItems_Entry{
        id
        typeHandle
        children{
          title
          ...on footer_item_Entry{
            lightswitch
            internalLink{
              uri
            }
            externalLink
          }
        }
      }
    }
  }
`

const QUERY_BOTTOM_FOOTER = gql`
  query getBottomFooter($siteHandle: [String]){
      globalSet(site: $siteHandle){
        ...on copyright_GlobalSet{
          header
          licensesArea{
            ...on licensesArea_item_BlockType{
              text
              licenseLink
            }
          }
          subheader
          legacyArea{
            ...on legacyArea_item_BlockType{
              text
              licenseLink
              licenseEntry{
                slug
              }
            }
          }
        }
      }
  }
`
