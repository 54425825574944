import React, { useEffect, useState } from 'react'
import { GetStaticProps, GetStaticPropsResult, GetStaticPropsContext } from 'next'
import { getPageSkeleton } from 'lib/pageInit'
import MainLayout from 'components/main-layout/index'
import styles from './style.module.scss'
import { useRouter } from 'next/router'
import { useStore } from 'hooks/locationContext'

const custom404Links = ['house-land']

function replace404Content(href: string, slug: string) {
  const oldContainer = window.document.getElementById('content404')
  const newContainer = window.document.getElementById(`content404_custom_${slug}`)
  const ctaLink = window.document.getElementById('cta_link')
  oldContainer.style.display = 'none'
  newContainer.style.display = 'flex'
  ctaLink.setAttribute('href', href)
}

export type PageNotFoundProps = {
  headerData?: any
  footerData?: any
  bottomFooterData?: any
}

function PageNotFound(): JSX.Element {
  const [data, setData] = useState<PageNotFoundProps>({})
  const currentLocation = useStore()[0]
  const { region } = currentLocation.initialLocation

  useEffect(() => {
    async function fetchData() {
      const { headerData, footerData, bottomFooterData } = await getPageSkeleton(region)
      setData({ headerData, footerData, bottomFooterData })
    }
    fetchData()
  }, [region])

  const router = useRouter()
  useEffect(() => {
    const path = router.asPath
    custom404Links.forEach((slug: string) => {
      if (path.includes(slug)) {
        const href = path.slice(0, path.lastIndexOf(slug) + slug.length)
        replace404Content(href, slug)
      }
    })
  }, [router.isReady])

  const { headerData, footerData, bottomFooterData } = data

  return (
    <MainLayout title="404 Page Not Found" headerData={headerData} footerData={footerData} bottomFooterData={bottomFooterData}>
      <div className={styles.page404} id="content404">
        <h1>404</h1>
        <p>Oops! That page wasn’t found.</p>
      </div>
      <div className={styles.page404} id="content404_custom_house-land" style={{ display: 'none', background: 'white' }}>
        <h2>Sorry, this package is no longer available</h2>
        <p className={styles.custom_p}>
          The house and land package you are trying to access is no longer available for sale.
          <br />
          Start a new search to find currently available packages in your area.
        </p>
        <a id="cta_link" href="">
          Show available packages
        </a>
      </div>
    </MainLayout>
  )
}

export default PageNotFound
